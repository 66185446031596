import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Other from './other'



export const Others = () => {
   

    const otherStories = useStaticQuery(graphql`
    query {
        allContentfulMemberStories(filter: {featured: {eq: false}}) {
          edges {
            node {
              name
              memberStory{
                raw
              }
              location
            }
          }
        }
      }`)

      const colors = ["yellow","blue","pink"]
      const allOtherStory = otherStories.allContentfulMemberStories.edges.map(
          (otherStory, i) => 
              <Other key={i} 
              story={otherStory.node.memberStory.raw }  
              name={otherStory.node.name} 
              location={otherStory.node.location} color={colors[(i%3)]}
              />
          
         )
    return(
        <section className="max-w-screen-xl mx-auto  grid gap-3 sm:grid-cols-2 grid-cols-1 mb-32">
            {allOtherStory}
        </section>
    )
}
