import React from 'react'
import {BLOCKS} from '@contentful/rich-text-types'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {graphql, useStaticQuery} from 'gatsby'

import Img from 'gatsby-image'

import Fade from 'react-reveal/Fade'


export const Featured = ({image, story, name, location}) => {

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH] : (noder, children) => <p>{children}</p>
    }
  }
    const featuredStories = useStaticQuery(graphql `
    query {
        allContentfulMemberStories(sort: {fields: createdAt, order: DESC}, filter: {featured: {eq: true}}) {
          edges {
            node {
              image {
                fluid(maxWidth: 500){
                  ...GatsbyContentfulFluid
               } 
              }
              memberStory{
                raw
              }
              id
              name
              location
             
            }
          }
        }
      }
    `)

    const allFeaturedStory = featuredStories.allContentfulMemberStories.edges.map(memberStory => <article key={memberStory.node.id} className="max-w-screen-xl mx-auto justify-between overflow-hidden items-center flex flex-wrap my-20 px-12">
          <Fade right cascade>
    <header className="sm:w-3/12 w-8/12">
          <Img fluid={memberStory.node.image.fluid} alt={memberStory.node.name} className="w-10/12"/>
          
        
    </header>
    <content className="sm:w-9/12 w-full sm:mt-0 mt-8">
        {documentToReactComponents(JSON.parse(memberStory.node.memberStory.raw), options) }
        <h4 className="font-sans mt-4">{memberStory.node.name}, {memberStory.node.location}</h4>
    </content>
      </Fade>
</article>)
    return(
        <>
        {allFeaturedStory}
        </>
        
    )
}
