import React from 'react'

import Layout from "../components/layout"
import {PageHeader, Subfooter} from "../components/utils"
import {Featured, Others} from "../components/stories"
import SEO from "../components/seo"

import Tomie from "../images/tomie-balogun.png"

const MemberStories = () => {
    return(
        <Layout color="yellow">
            <SEO title="Member Stories" />
            <PageHeader color="yellow" heading={<>Better decisions, <br className="sm:hidden"/>higher returns... <br/>and new-born investment clubs</>}
            paragraph="The Green Investment Club (TGIC) is a community-based investment club focused on helping young people live the lives they want by providing access to financial education, insights and opportunities towards achieving their financial goals." />

            <Featured image={Tomie} story={<><p>Before I joined The Green Investment Club (TGIC) I had a good saving habit but I didn’t know much about investing. I had heard about some investment opportunities but as a typical Nigerian, I was quite sceptical. I didn’t want to lose money or end up being swindled.</p>

So far, it has been a good experience. Every member of my family is now by extension, a member of the Green Club. I share the opportunities and we pool resources to invest. My dad had a bad experience from the stock market crash and has stayed away since then. Now he is back and making better informed decisions about the stock market. 
</>} name="Tolulope" location="Nigeria" />
            <Others />
            <Subfooter text="Can’t wait for your own success story?" />
        </Layout>
        
    )
}

export default MemberStories